import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { addFoodItem, clearAllFoodItems } from "../store/food-items-slice";
import FoodItem from "./food-item";

const FoodList: React.FC = () => {
  const foodItems = useSelector((state: RootState) => state.foodItems.items);
  const dispatch = useDispatch();

  const handleAddFoodItem = () => {
    const newFoodItem = {
      id: Date.now(), // Example ID, use a better unique ID in production
      type: "",
      carbs: 0,
      servingSize: 1,
    };
    dispatch(addFoodItem(newFoodItem));
  };

  const handleClearAllFoodItems = () => {
    if (confirm("Are you sure you want to clear all food items?")) {
      dispatch(clearAllFoodItems());
    }
  };

  return (
    <div className="flex flex-col items-center h-full">
      <div className="flex flex-col mb-2 w-full max-w-md mx-auto items-center h-min-screen overflow-y-auto ">
        <ul className="w-full max-w-md">
          <div className="flex justify-between text-xs text-gray-500 mb-1">
            <span className="flex-1 pl-6">Food</span>
            <span className="w-10">Carbs</span>
            <span className="w-10">Servings</span>
            <span className="w-10 ml-6">{/* remove item */} </span>
          </div>
          {foodItems.map((item) => (
            <FoodItem key={item.id} {...item} />
          ))}
        </ul>
        <div>
          <button
            className="m-4 p-2 bg-blue-500 text-white rounded shadow"
            onClick={handleAddFoodItem}
          >
            Add New Food Item
          </button>
          <button
            className="m-4 p-2 bg-blue-500 text-white rounded shadow"
            onClick={handleClearAllFoodItems}
          >
            Clear
          </button>
          <div className="h-40"></div>
        </div>
      </div>
    </div>
  );
};

export default FoodList;
