import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CurrentlyLoadingLogState {
  value: boolean;
}

const initialState: CurrentlyLoadingLogState = {
  value: false,
};

const currentlyLoadingLogSlice = createSlice({
  name: "currentlyLoadingLog",
  initialState,
  reducers: {
    setCurrentlyLoadingLog: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setCurrentlyLoadingLog } = currentlyLoadingLogSlice.actions;
export default currentlyLoadingLogSlice.reducer;
