import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import LogItem from "./log-item";
import LogItemDetailModal from "./log-item-detail-modal";
import { FoodLogEntry } from "../constants";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { fetchDosageLogEntries } from "../store/dosage-log-slice";
import { setCurrentlyLoadingLog } from "../store/currently-loading-log-slice";

const DosageLog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const currentlyLoadingLog = useSelector(
    (state: RootState) => state.currentlyLoadingLog
  );

  const { items, loading, reloading, error } = useSelector(
    (state: RootState) => state.dosageLog
  );

  const [selectedLogItem, setSelectedLogItem] = useState<FoodLogEntry | null>(
    null
  );

  const handleShowDetails = (foodLogItem: FoodLogEntry) => {
    setSelectedLogItem(foodLogItem);
  };

  const handleCloseModal = () => {
    setSelectedLogItem(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleRefresh = () => {
    dispatch(fetchDosageLogEntries());
  };

  const handleNoop = () => {};

  return (
    <div className="p-4 bg-white shadow rounded w-full max-w-md mx-auto mt-4 relative">
      <h2 className="text-xl font-bold mb-4">Dosage Log</h2>
      {currentlyLoadingLog.value === true && (
        <button
          onClick={handleNoop}
          className="absolute top-4 right-4 p-1 bg-gray-200 rounded-full animate-spin"
        >
          <ArrowPathIcon className="h-5 w-5" />
        </button>
      )}
      {currentlyLoadingLog.value === false && (
        <button
          onClick={handleRefresh}
          className="absolute top-4 right-4 p-1 bg-gray-200 rounded-full animate-none"
        >
          <ArrowPathIcon className="h-5 w-5" />
        </button>
      )}

      <ul>
        {items.map((item) => (
          <LogItem
            key={item.id}
            foodLogItem={item}
            onShowDetails={handleShowDetails}
          ></LogItem>
        ))}
      </ul>
      {selectedLogItem && (
        <LogItemDetailModal
          foodLogItem={selectedLogItem}
          onClose={handleCloseModal}
        />
      )}
      <div className="h-40"></div>
    </div>
  );
};

export default DosageLog;
