import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./index";

export const selectTotalCarbs = createSelector(
  [(state: RootState) => state.foodItems.items],
  (items) =>
    items.reduce((total, item) => {
      return total + item.servingSize * item.carbs;
    }, 0)
);

export const selectCurrentBloodSugar = (state: RootState) =>
  state.currentBloodSugar.value;

export const getCurrentBloodSugar = createSelector(
  [selectCurrentBloodSugar],
  (currentBloodSugar) => currentBloodSugar
);

export const selectLastSuccessfulLogLoad = (state: RootState) =>
  state.lastSuccessfulLogLoad.value;

export const getLastSuccessfulLogLoad = createSelector(
  [selectLastSuccessfulLogLoad],
  (lastSuccessfulLogLoad) => lastSuccessfulLogLoad
);

export const selectManualAdministeredDose = (state: RootState) =>
  state.manualAdministeredDose.value;

export const getManualAdministeredDose = createSelector(
  [selectManualAdministeredDose],
  (manualAdministeredDose) => manualAdministeredDose
);

export const selectCurrentlyLoadingLog = (state: RootState) =>
  state.currentlyLoadingLog.value;

export const getCurrentlyLoadingLog = createSelector(
  [selectCurrentlyLoadingLog],
  (currentlyLoadingLog) => currentlyLoadingLog
);

export const selectLastSuccessfulChartLoad = (state: RootState) =>
  state.lastSuccessfulChartLoad.value;

export const getLastSuccessfulChartLoad = createSelector(
  [selectLastSuccessfulChartLoad],
  (lastSuccessfulChartLoad) => lastSuccessfulChartLoad
);

export const selectCurrentlyLoadingChart = (state: RootState) =>
  state.currentlyLoadingChart.value;

export const getCurrentlyLoadingChart = createSelector(
  [selectCurrentlyLoadingChart],
  (currentlyLoadingChart) => currentlyLoadingChart
);

export const selectAuthKey = (state: RootState) => state.auth.apiKey;

export const getAuthKey = createSelector([selectAuthKey], (authKey) => authKey);

export const selectLogVersion = (state: RootState) => state.auth.logVersion;

export const getLogVersion = createSelector(
  [selectLogVersion],
  (logVersion) => logVersion
);

export const selectCurrentFoodItems = createSelector(
  [(state: RootState) => state.foodItems.items],
  (items) =>
    items.map((item) => {
      return {
        id: item.id,
        type: item.type,
        carbs: item.carbs,
        servingSize: item.servingSize,
      };
    })
);
