import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import MealBuilderPage from "./components/meal-builder-page";
import CalculateDosePage from "./components/calculate-dose-page";
import DosageLog from "./components/dosage-log";
import { fetchDosageLogEntries } from "./store/dosage-log-slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import { authorize } from "./store/auth-slice";
import PinModal from "./components/pin-modal";
import { FIVE_MINUTES, OBTAIN_AUTH_URL } from "./constants";
import GraphPage from "./components/graph-page";
import { fetchGlucoseHistoryChartEntries } from "./store/glucose-history-chart-slice";

let nextSafeLogFetchTimeout = Date.now();

function App() {
  const dispatch = useDispatch<AppDispatch>();

  const isAuthorized = useSelector(
    (state: RootState) => state.auth.isAuthorized
  );
  const [showPinModal, setShowPinModal] = useState(!isAuthorized);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(fetchDosageLogEntries());
      dispatch(fetchGlucoseHistoryChartEntries());
    }
  }, [dispatch, isAuthorized]);

  const handlePinSubmit = async (pin: string) => {
    try {
      const response = await fetch(OBTAIN_AUTH_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pin,
        }),
      });
      const data = await response.json();

      if (data.auth && data.logVersion) {
        dispatch(
          authorize({
            isAuthorized: true,
            apiKey: data.auth,
            logVersion: data.logVersion,
          })
        );
        setShowPinModal(false);
      }
    } catch (err) {
      alert("Invalid PIN");
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (isAuthorized && nextSafeLogFetchTimeout < Date.now()) {
          nextSafeLogFetchTimeout = Date.now() + FIVE_MINUTES;
          dispatch(fetchDosageLogEntries());
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []); // Empty dependency array ensures this effect runs once on mount and cleanup on unmount

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<MealBuilderPage />} />
          <Route path="/dose" element={<CalculateDosePage />} />
          <Route path="/log" element={<DosageLog />} />
          <Route path="/graph" element={<GraphPage />} />
        </Routes>
      </Layout>
      {showPinModal && <PinModal onPinSubmit={handlePinSubmit} />}
    </Router>
  );
}

export default App;
