import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { FoodLogEntry, READ_FOOD_LOG_URL, FoodLogState } from "../constants";
import { setLastSuccessfulLogLoad } from "./last-successful-log-load-slice";
import { RootState } from ".";
import { setCurrentlyLoadingLog } from "./currently-loading-log-slice";

const initialState: FoodLogState = {
  items: [],
  loading: false,
  reloading: false,
  error: null,
};

let debounceTimeout: ReturnType<typeof setTimeout> | null = null;
let localLastSuccessfulLogLoad = 0;

// Fetch log entries from API
export const fetchDosageLogEntries = createAsyncThunk(
  "log/fetchLogEntries",
  async (_, { getState, dispatch }) => {
    return new Promise<FoodLogEntry[]>((resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      debounceTimeout = setTimeout(async () => {
        const state = getState() as RootState;
        const authKey = state.auth.apiKey;
        const logVersion = state.auth.logVersion;

        try {
          dispatch(setCurrentlyLoadingLog(true));
          const response = await fetch(
            `${READ_FOOD_LOG_URL}?ts=${Date.now()}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                auth: authKey,
                version: logVersion,
              }),
            }
          );
          const data = await response.json();
          dispatch(setLastSuccessfulLogLoad(Date.now()));
          dispatch(setCurrentlyLoadingLog(false));
          localLastSuccessfulLogLoad = Date.now();
          resolve(data);
        } catch (err) {
          dispatch(setCurrentlyLoadingLog(false));
          reject(err);
        }
      }, 100); // Debounce delay
    });
  }
);

const dosageLogSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    addDosageLogEntry: (state, action: PayloadAction<FoodLogEntry>) => {
      return {
        ...state,
        items: [action.payload, ...state.items],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDosageLogEntries.pending, (state) => {
        if (localLastSuccessfulLogLoad === 0) {
          return {
            ...state,
            loading: true,
            reloading: false,
            error: null,
          };
        } else {
          return {
            ...state,
            loading: false,
            reloading: true,
            error: null,
          };
        }
      })
      .addCase(
        fetchDosageLogEntries.fulfilled,
        (state, action: PayloadAction<FoodLogEntry[]>) => {
          return {
            ...state,
            loading: false,
            reloading: false,
            items: action.payload,
          };
        }
      )
      .addCase(fetchDosageLogEntries.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          reloading: false,
          error: action.error.message || "Failed to fetch log entries",
        };
      });
  },
});

export const { addDosageLogEntry } = dosageLogSlice.actions;
export default dosageLogSlice.reducer;
