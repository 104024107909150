import React from "react";
import FoodList from "./food-list";

const MealBuilderPage = () => {
  return (
    <div>
      <ul className="list-disc">
        <FoodList />
      </ul>
    </div>
  );
};

export default MealBuilderPage;
