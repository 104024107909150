import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectTotalCarbs,
  selectCurrentBloodSugar,
  selectCurrentFoodItems,
  selectAuthKey,
  selectLogVersion,
  selectManualAdministeredDose,
} from "../store/selectors";
import { AppDispatch, RootState } from "../store";
import { setCurrentBloodSugar } from "../store/blood-sugar-slice";
import { clearAllFoodItems } from "../store/food-items-slice";

import {
  MEAL_OPTIONS,
  TEN_MINUTES,
  GET_GLUCOSE_URL,
  LOG_FOOD_URL,
  MealOptions,
  BloodInfo,
  BloodDisplay,
  FetchResult,
  FoodLogEntry,
  DoseMode,
  DoseInfo,
} from "../constants";
import { addDosageLogEntry } from "../store/dosage-log-slice";
import { setManualAdministeredDose } from "../store/manual-administered-dose-slice";

let nextRequestTime = 0;
let nextLogTime = 0;
let logRequestInFlight = false;

const getCurrentTime = () => {
  const hour = new Date().getHours();
  if (hour >= 4 && hour < 9) {
    return MEAL_OPTIONS[1]; // Breakfast
  } else if (hour >= 9 && hour < 11) {
    return MEAL_OPTIONS[2]; // AM snack
  } else if (hour >= 11 && hour < 13) {
    return MEAL_OPTIONS[3]; // Lunch
  } else if (hour >= 13 && hour < 15) {
    return MEAL_OPTIONS[4]; // PM snack
  } else if (hour >= 15 && hour < 19) {
    return MEAL_OPTIONS[5]; // Dinner
  } else {
    return MEAL_OPTIONS[6]; // Night snack
  }
};

const calculateDoses = (
  currentBloodSugar: number,
  totalCarbs: number,
  meal: MealOptions
) => {
  const foodDose =
    totalCarbs * (meal.carbRatioNumerator / meal.carbRatioDenominator);
  const correction =
    (currentBloodSugar - meal.targetBloodSugar) / meal.sensitivityFactor;
  const totalDose = foodDose + correction;

  const doseInfo: DoseInfo = {
    currentBloodSugar,
    foodDose,
    correction,
    totalDose,
  };

  return doseInfo;
};

const processBloodInfo = (info: BloodInfo): BloodDisplay => {
  if (info.init) {
    return {
      fetchResult: FetchResult.NotSent,
      fetchTime: new Date(0).toISOString(),
      trend: "",
    };
  }
  if (info.loading) {
    return {
      fetchResult: FetchResult.Loading,
      fetchTime: new Date(0).toISOString(),
      trend: "",
    };
  }
  if (info.value && info.time && info.trendArrow) {
    const fetchTime = new Date(info.time);
    if (fetchTime.getTime() + TEN_MINUTES > Date.now()) {
      return {
        fetchResult: FetchResult.Success,
        fetchTime: fetchTime.toISOString(),
        trend: info!.trendArrow,
      };
    } else {
      return {
        fetchResult: FetchResult.Stale,
        fetchTime: fetchTime.toISOString(),
        trend: info!.trendArrow,
      };
    }
  } else {
    return {
      fetchResult: FetchResult.Fail,
      fetchTime: new Date(0).toISOString(),
      trend: "",
    };
  }
};

const roundDose = (
  floatDose: number,
  doseMode: DoseMode,
  manualAdministeredDose: number
): number => {
  switch (doseMode) {
    case DoseMode.RoundUp:
      return Math.ceil(floatDose * 2) / 2;
    case DoseMode.RoundDown:
      return Math.floor(floatDose * 2) / 2;
    case DoseMode.Manual:
      return manualAdministeredDose;
  }
};

const getLogRequestInFlight = () => {
  return logRequestInFlight;
};

const InsulinDoseCalculator: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const totalCarbs = useSelector((state: RootState) => selectTotalCarbs(state));
  const currentBloodSugar = useSelector((state: RootState) =>
    selectCurrentBloodSugar(state)
  );
  const authKey = useSelector((state: RootState) => selectAuthKey(state));
  const logVersion = useSelector((state: RootState) => selectLogVersion(state));
  const foodItems = useSelector((state: RootState) =>
    selectCurrentFoodItems(state)
  );
  const [meal, setMeal] = useState(getCurrentTime());
  const [doseInfo, setDoseInfo] = useState(
    calculateDoses(currentBloodSugar, totalCarbs, meal)
  );
  const [bloodDisplay, setBloodDisplay] = useState(
    processBloodInfo({ init: true })
  );
  const [logRequestInFlight, setLogRequestInFlight] = useState(
    getLogRequestInFlight()
  );
  const manualAdministeredDose = useSelector((state: RootState) =>
    selectManualAdministeredDose(state)
  );

  const handleMealChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMeal = MEAL_OPTIONS.find(
      (option) => option.label === e.target.value
    );
    if (selectedMeal) {
      setMeal(selectedMeal);
      setDoseInfo(calculateDoses(currentBloodSugar, totalCarbs, selectedMeal));
    }
  };

  const handleBloodSugarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const bloodSugarValue = Number(e.target.value);
    setDoseInfo(calculateDoses(bloodSugarValue, totalCarbs, meal));
    dispatch(setCurrentBloodSugar(bloodSugarValue));
  };

  const handleCarbNumeratorChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    meal.carbRatioNumerator = Number(e.target.value);
    setDoseInfo(calculateDoses(currentBloodSugar, totalCarbs, meal));
  };

  const handleCarbDenominatorChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    meal.carbRatioDenominator = Number(e.target.value);
    setDoseInfo(calculateDoses(currentBloodSugar, totalCarbs, meal));
  };

  const handleSensitivityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    meal.sensitivityFactor = Number(e.target.value);
    setDoseInfo(calculateDoses(currentBloodSugar, totalCarbs, meal));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleGetBloodGlucose = async () => {
    if (Date.now() < nextRequestTime) {
      return;
    }
    nextRequestTime = Date.now() + 30000;
    setBloodDisplay(processBloodInfo({ loading: true }));
    try {
      const response = await fetch(GET_GLUCOSE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          auth: authKey,
        }),
      });
      const data = await response.json();

      if (data.value) {
        const bloodSugarValue = Number(data.value);
        setDoseInfo(calculateDoses(bloodSugarValue, totalCarbs, meal));
        dispatch(setCurrentBloodSugar(bloodSugarValue));
        setBloodDisplay(processBloodInfo(data));
      } else {
        const bloodSugarValue = Number(0);
        dispatch(setCurrentBloodSugar(bloodSugarValue));
        setBloodDisplay(
          processBloodInfo({
            init: false,
          })
        );
      }
    } catch (err) {
      setBloodDisplay(processBloodInfo({ init: false }));
    }
  };

  const handleLogFoodRoundUp = async () => {
    return handleLogFood(DoseMode.RoundUp);
  };

  const handleLogFoodRoundDown = async () => {
    return handleLogFood(DoseMode.RoundDown);
  };

  const handleLogFoodManualDose = async () => {
    return handleLogFood(DoseMode.Manual);
  };

  const handleManualAdministeredDoseChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    dispatch(setManualAdministeredDose(Number(e.target.value)));
  };

  const isValidDose = (doseInfo: DoseInfo) => {
    return (
      !isNaN(doseInfo.foodDose) &&
      isFinite(doseInfo.foodDose) &&
      isFinite(doseInfo.totalDose)
    );
  };

  const handleLogFood = async (doseMode: DoseMode) => {
    if (Date.now() < nextLogTime) {
      alert("Please wait 1 minute before logging another meal.");
      return;
    }
    nextLogTime = Date.now() + 60000;
    const administeredDose = roundDose(
      doseInfo.totalDose,
      doseMode,
      manualAdministeredDose
    );
    setLogRequestInFlight(true);

    const jsonPayload: FoodLogEntry = {
      id: Date.now(),
      logVersion,
      foodItems,
      mealTime: new Date().toISOString(),
      sensitivityFactor: meal.sensitivityFactor,
      carbRatioNumerator: meal.carbRatioNumerator,
      carbRatioDenominator: meal.carbRatioDenominator,
      currentBloodSugar,
      targetBloodSugar: meal.targetBloodSugar,
      custom: false,
      foodDose: doseInfo.foodDose,
      correction: doseInfo.correction,
      totalDose: doseInfo.totalDose,
      administeredDose,
    };

    if (bloodDisplay) {
      jsonPayload.sensorData = {
        fetchResult: bloodDisplay.fetchResult,
        fetchTime: bloodDisplay.fetchTime,
        trend: bloodDisplay.trend,
      };
    }

    try {
      const response = await fetch(LOG_FOOD_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          auth: authKey,
          logEntry: jsonPayload,
        }),
      });

      if (response.ok) {
        setBloodDisplay(processBloodInfo({ init: true }));
        dispatch(setCurrentBloodSugar(0));
        dispatch(clearAllFoodItems());
        dispatch(addDosageLogEntry(jsonPayload));
        setLogRequestInFlight(false);
      }
    } catch (err) {
      alert("Warning: Meal was not recorded! Please try again.");
      console.error("Error writing dose log.");
      console.error(err);
      setLogRequestInFlight(false);
    }
  };

  return (
    <div className="p-4 bg-white shadow rounded w-full max-w-md mx-auto mt-4">
      <h2 className="text-xl font-bold">Insulin Dose Calculator</h2>
      <div className="mb-4">
        <label className="block mb-2">Meal Time:</label>
        <select
          value={meal.label}
          onChange={handleMealChange}
          className="border rounded p-2 w-full"
        >
          {MEAL_OPTIONS.map((option) => (
            <option key={option.label} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Current Blood Sugar:</label>
        {bloodDisplay.fetchResult === FetchResult.Loading && (
          <label className="block mb-2">Loading...</label>
        )}
        {bloodDisplay.fetchResult === FetchResult.Success && (
          <label className="block mb-2">
            {new Date(bloodDisplay.fetchTime).toLocaleDateString()} -{" "}
            {new Date(bloodDisplay.fetchTime).toLocaleTimeString()} - Trend:{" "}
            {bloodDisplay.trend}
          </label>
        )}
        {bloodDisplay.fetchResult === FetchResult.Stale && (
          <label className="block mb-2 bg-red-500">
            <div className="block mb-2">Blood sugar reading too old -</div>
            {new Date(bloodDisplay.fetchTime).toLocaleDateString()} -{" "}
            {new Date(bloodDisplay.fetchTime).toLocaleTimeString()} - Trend:{" "}
            {bloodDisplay.trend}
          </label>
        )}
        {bloodDisplay.fetchResult === FetchResult.Fail && (
          <label className="block mb-2 bg-red-500">
            Blood sugar reading failed
          </label>
        )}
        <div className="flex">
          <input
            type="number"
            value={doseInfo.currentBloodSugar}
            className="border rounded p-2 w-full m-0 mr-1 flex-1"
            onChange={handleBloodSugarChange}
            onFocus={handleFocus}
          />
          <button
            onClick={handleGetBloodGlucose}
            className="py-2 px-4 rounded-lg shadow-lg"
          >
            {bloodDisplay.fetchResult !== FetchResult.Loading && "🩸"}
            {bloodDisplay.fetchResult === FetchResult.Loading && "🕚"}
          </button>
        </div>
      </div>
      {meal.custom && (
        <div className="mb-4">
          <label className="block mb-2">Custom Carb Ratio:</label>
          <div className="flex">
            <input
              type="number"
              value={meal.carbRatioNumerator}
              placeholder="Carbs"
              className="border rounded p-2 w-1/2 m-0 mr-1 flex-1"
              onChange={handleCarbNumeratorChange}
              onFocus={handleFocus}
            />
            <input
              type="number"
              value={meal.carbRatioDenominator}
              placeholder="Carbs"
              className="border rounded p-2 w-1/2 m-0 mr-1 flex-1"
              onChange={handleCarbDenominatorChange}
              onFocus={handleFocus}
            />
          </div>
        </div>
      )}
      {meal.custom && (
        <div className="mb-4">
          <label className="block mb-2">Sensitivity:</label>
          <input
            type="number"
            value={meal.sensitivityFactor}
            placeholder="Carbs"
            className="border rounded p-2 w-full m-0 mr-1 flex-1"
            onChange={handleSensitivityChange}
            onFocus={handleFocus}
          />
        </div>
      )}

      <p className="text-lg">Target Sugar: {meal.targetBloodSugar}</p>
      <p className="text-lg">
        Carb ratio: {meal.carbRatioNumerator} / {meal.carbRatioDenominator}
      </p>
      <p className="text-lg">Insulin Sensitivity: {meal.sensitivityFactor}</p>

      <br />
      <hr />
      <br />

      {isNaN(doseInfo.foodDose) && (
        <div>
          <p>Carb ratio must be greater than zero.</p>
        </div>
      )}
      {!isFinite(doseInfo.totalDose) && (
        <div>
          <p>Dose is invalid.</p>
        </div>
      )}
      {isValidDose(doseInfo) && (
        <div>
          <p className="text-lg">Food Dose: {doseInfo.foodDose.toFixed(2)}</p>
          <p className="text-lg">
            Correction: {doseInfo.correction.toFixed(2)} units
          </p>
          <p className="text-lg">
            Total Dose: {doseInfo.totalDose.toFixed(2)} units
          </p>
        </div>
      )}
      <br />
      <hr />
      <br />
      {isValidDose(doseInfo) &&
        doseInfo.foodDose > 0 &&
        currentBloodSugar > 0 &&
        !logRequestInFlight && (
          <div>
            <div className="flex space-x-4 justify-center items-center">
              <button
                onClick={handleLogFoodRoundDown}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
              >
                Log Dose as{" "}
                {roundDose(
                  doseInfo.totalDose,
                  DoseMode.RoundDown,
                  manualAdministeredDose
                )}
              </button>
              <button
                onClick={handleLogFoodRoundUp}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
              >
                Log Dose as{" "}
                {roundDose(
                  doseInfo.totalDose,
                  DoseMode.RoundUp,
                  manualAdministeredDose
                )}
              </button>
            </div>
            <div className="flex space-x-4 justify-center items-center mt-8">
              <select
                onChange={handleManualAdministeredDoseChange}
                className="border rounded p-2 w-15"
              >
                <option value="0.0">0.0</option>
                <option value="0.5">0.5</option>
                <option value="1.0">1.0</option>
                <option value="1.5">1.5</option>
                <option value="2.0">2.0</option>
                <option value="2.5">2.5</option>
                <option value="3.0">3.0</option>
                <option value="3.5">3.5</option>
                <option value="4.0">4.0</option>
                <option value="4.5">4.5</option>
                <option value="5.0">5.0</option>
                <option value="5.5">5.5</option>
                <option value="6.0">6.0</option>
                <option value="6.5">6.5</option>
                <option value="7.0">7.0</option>
                <option value="7.5">7.5</option>
                <option value="8.0">8.0</option>
              </select>

              <button
                onClick={handleLogFoodManualDose}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
              >
                Log Dose Manually
              </button>
            </div>
          </div>
        )}
      {logRequestInFlight && (
        <div className="flex space-x-4 justify-center items-center">
          <div className="bg-blue-300 text-white font-bold py-2 px-4 rounded">
            Logging...
          </div>
        </div>
      )}
      <div className="h-40"></div>
    </div>
  );
};

export default InsulinDoseCalculator;
