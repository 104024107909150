import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FoodItem {
  id: number;
  type: string;
  carbs: number;
  servingSize: number;
}

export interface FoodItemsState {
  items: FoodItem[];
}

const initialState: FoodItemsState = {
  items: [],
};

const foodItemsSlice = createSlice({
  name: "foodItems",
  initialState,
  reducers: {
    addFoodItem: (state, action: PayloadAction<FoodItem>) => {
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    },
    updateFoodItem: (state, action: PayloadAction<FoodItem>) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        const newItems = [...state.items];
        newItems[index] = action.payload;
        return {
          ...state,
          items: newItems,
        };
      }
      return state;
    },
    removeFoodItem: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    },
    clearAllFoodItems: (state) => {
      return {
        ...state,
        items: [],
      };
    },
  },
});

export const {
  addFoodItem,
  updateFoodItem,
  removeFoodItem,
  clearAllFoodItems,
} = foodItemsSlice.actions;
export default foodItemsSlice.reducer;
