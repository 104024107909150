import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ManualAdministeredDoseState {
  value: number;
}

const initialState: ManualAdministeredDoseState = {
  value: 0,
};

const manualAdministeredDoseSlice = createSlice({
  name: "manualAdministeredDose",
  initialState,
  reducers: {
    setManualAdministeredDose: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setManualAdministeredDose } =
  manualAdministeredDoseSlice.actions;
export default manualAdministeredDoseSlice.reducer;
