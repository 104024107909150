import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LastSuccessfulChartLoadState {
  value: number;
}

const initialState: LastSuccessfulChartLoadState = {
  value: 0,
};

const lastSuccessfulChartLoadSlice = createSlice({
  name: "lastSuccessfulChartLoad",
  initialState,
  reducers: {
    setLastSuccessfulChartLoad: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setLastSuccessfulChartLoad } =
  lastSuccessfulChartLoadSlice.actions;
export default lastSuccessfulChartLoadSlice.reducer;
