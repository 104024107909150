import React from "react";
import { useDispatch } from "react-redux";
import { FoodLogEntry } from "../constants";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

interface LogItemProps {
  foodLogItem: FoodLogEntry;
  onShowDetails: (foodLogItem: FoodLogEntry) => void;
}

const LogItem: React.FC<LogItemProps> = ({ foodLogItem, onShowDetails }) => {
  const carbs = foodLogItem.foodItems.reduce((total, item) => {
    return total + item.carbs;
  }, 0);

  return (
    <li
      key={foodLogItem.id}
      className="flex justify-between items-center mb-2 border-b pb-2"
    >
      <div>
        <div>
          <strong>Meal Time:</strong>{" "}
          {new Date(foodLogItem.mealTime).toLocaleDateString()} -{" "}
          {new Date(foodLogItem.mealTime).toLocaleTimeString()}
        </div>
        {!!foodLogItem.administeredDose && (
          <div>
            <strong>Administered Dose:</strong>{" "}
            {foodLogItem.administeredDose?.toFixed(1)}
          </div>
        )}
        <div>
          <strong>Calculated Dose:</strong> {foodLogItem.totalDose?.toFixed(2)}
        </div>
        <div>
          <strong>Blood Sugar:</strong> {foodLogItem.currentBloodSugar}
        </div>
      </div>
      <button
        onClick={() => onShowDetails(foodLogItem)}
        className="p-2 bg-green-500 text-white rounded-full"
      >
        <MagnifyingGlassIcon className="h-5 w-5" />
      </button>
    </li>
  );
};

export default LogItem;
