import { configureStore } from "@reduxjs/toolkit";
import foodItemsReducer from "./food-items-slice";
import currentBloodSugarReducer from "./blood-sugar-slice";
import dosageLogSliceReducer from "./dosage-log-slice";
import authReducer from "./auth-slice";
import lastSuccessfulLogLoadReducer from "./last-successful-log-load-slice";
import currentlyLoadingLogReducer from "./currently-loading-log-slice";
import { loadState, saveState } from "./localStorage";
import manualAdministeredDoseSlice from "./manual-administered-dose-slice";
import currentlyLoadingChartReducer from "./currently-loading-chart-slice";
import lastSuccessfulChartLoadReducer from "./last-successful-chart-load-slice";
import glucoseHistoryChartReducer from "./glucose-history-chart-slice";

const preloadedState = loadState();

const store = configureStore({
  reducer: {
    foodItems: foodItemsReducer,
    currentBloodSugar: currentBloodSugarReducer,
    dosageLog: dosageLogSliceReducer,
    auth: authReducer,
    lastSuccessfulLogLoad: lastSuccessfulLogLoadReducer,
    currentlyLoadingLog: currentlyLoadingLogReducer,
    manualAdministeredDose: manualAdministeredDoseSlice,
    lastSuccessfulChartLoad: lastSuccessfulChartLoadReducer,
    currentlyLoadingChart: currentlyLoadingChartReducer,
    glucoseHistoryChart: glucoseHistoryChartReducer,
  },
  preloadedState,
});

store.subscribe(() => {
  saveState({
    foodItems: store.getState().foodItems,
    auth: store.getState().auth,
  });
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
