import { FoodItemsState } from "./food-items-slice";

const EMPTY_FOOD_ITEMS_STATE: { foodItems: FoodItemsState } = {
  foodItems: { items: [] },
};

export const loadState = (): { foodItems: FoodItemsState } => {
  try {
    const serializedState = localStorage.getItem("foodItemsState");
    if (serializedState === null) {
      return EMPTY_FOOD_ITEMS_STATE;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Could not load state from localStorage", err);
    return EMPTY_FOOD_ITEMS_STATE;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("foodItemsState", serializedState);
  } catch (err) {
    console.error("Could not save state to localStorage", err);
  }
};
