import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CurrentlyLoadingChartState {
  value: boolean;
}

const initialState: CurrentlyLoadingChartState = {
  value: false,
};

const currentlyLoadingChartSlice = createSlice({
  name: "currentlyLoadingChart",
  initialState,
  reducers: {
    setCurrentlyLoadingChart: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setCurrentlyLoadingChart } = currentlyLoadingChartSlice.actions;
export default currentlyLoadingChartSlice.reducer;
