import React from "react";
import { FetchResult, FoodLogEntry } from "../constants";
import { XCircleIcon } from "@heroicons/react/24/solid";

interface LogDetailModalProps {
  foodLogItem: FoodLogEntry | null;
  onClose: () => void;
}

const fetchResultToString = (result: FetchResult): string => {
  switch (result) {
    case FetchResult.Success:
      return "Success";
    case FetchResult.Fail:
      return "Failed";
    case FetchResult.NotSent:
      return "Not Sent";
  }
  return "Unknown";
};

const LogItemDetailModal: React.FC<LogDetailModalProps> = ({
  foodLogItem,
  onClose,
}) => {
  if (!foodLogItem) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg w-80 max-h-full overflow-auto relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 bg-gray-200 rounded-full hover:bg-gray-300"
        >
          <XCircleIcon className="h-5 w-5" />
        </button>
        <div>
          <strong>Meal Date:</strong>{" "}
          {new Date(foodLogItem?.mealTime).toLocaleDateString()}
        </div>
        <div>
          <strong>Meal Time:</strong>{" "}
          {new Date(foodLogItem?.mealTime).toLocaleTimeString()}
        </div>
        <hr className="my-2" />
        <div>
          <strong>Food Items:</strong>{" "}
        </div>
        <ul>
          {foodLogItem?.foodItems.map((foodItem) => (
            <li className="flex w-full justify-between" key={foodItem.id}>
              <span className="flex-1 truncate">{foodItem.type}</span>
              <span>{foodItem.carbs} g</span>
              <span> ✖️ {foodItem.servingSize}</span>
            </li>
          ))}
        </ul>
        <div>
          <strong>Carb total:</strong>{" "}
          {foodLogItem?.foodItems.reduce((total, item) => {
            return total + item.carbs * item.servingSize;
          }, 0)}
        </div>
        <hr className="my-2" />
        <div>
          <strong>Sensitivity Factor:</strong> {foodLogItem?.sensitivityFactor}
        </div>
        <div>
          <strong>Carb Ratio:</strong> {foodLogItem?.carbRatioNumerator}
          {"/"}
          {foodLogItem?.carbRatioDenominator}
        </div>
        <div>
          <strong>Target Blood Sugar:</strong> {foodLogItem?.targetBloodSugar}
        </div>
        <div>
          <strong>Current Blood Sugar:</strong> {foodLogItem?.currentBloodSugar}
        </div>
        <hr className="my-2" />
        <div>
          <strong>Food Dose:</strong> {foodLogItem?.foodDose.toFixed(2)}
        </div>
        <div>
          <strong>Correction:</strong> {foodLogItem?.correction.toFixed(2)}
        </div>
        <div>
          <strong>Total Dose:</strong> {foodLogItem?.totalDose.toFixed(2)}
        </div>
        <div>
          <strong>Administered Dose:</strong>{" "}
          {foodLogItem?.administeredDose?.toFixed(1)}
        </div>
        {foodLogItem?.sensorData?.fetchResult === FetchResult.NotSent && (
          <div>
            <hr className="my-2" />
            <strong>Sensor Data:</strong>
            <div>No sensor data found.</div>
          </div>
        )}
        {!!foodLogItem?.sensorData &&
          foodLogItem?.sensorData?.fetchResult !== FetchResult.NotSent && (
            <div>
              <hr className="my-2" />
              <strong>Sensor Data:</strong>
              <div>
                <strong>Fetch Date:</strong>{" "}
                {new Date(
                  foodLogItem?.sensorData?.fetchTime
                ).toLocaleDateString()}
              </div>
              <div>
                <strong>Fetch Time:</strong>{" "}
                {new Date(
                  foodLogItem?.sensorData?.fetchTime
                ).toLocaleTimeString()}
              </div>
              <div>
                <strong>Fetch Result:</strong>{" "}
                {fetchResultToString(foodLogItem?.sensorData?.fetchResult)}
              </div>
              <div>
                <strong>Trend:</strong> {foodLogItem?.sensorData?.trend}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default LogItemDetailModal;
