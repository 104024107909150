import React from "react";
import {
  BeakerIcon,
  CalculatorIcon,
  CheckIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";
import CarbTotalBar from "./carb-total-bar";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchDosageLogEntries } from "../store/dosage-log-slice";
import { FIVE_MINUTES } from "../constants";
import { selectLastSuccessfulLogLoad } from "../store/selectors";

const TabBar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const lastSuccessfulLogLoad = useSelector((state: RootState) =>
    selectLastSuccessfulLogLoad(state)
  );

  const handleSwitchToLogTab = () => {
    if (Date.now() > lastSuccessfulLogLoad + FIVE_MINUTES) {
      dispatch(fetchDosageLogEntries());
    }
  };

  return (
    <div
      className="fixed bottom-0 w-full bg-white border-t border-gray-200"
      style={{ paddingBottom: "env(safe-area-inset-bottom)" }}
    >
      <CarbTotalBar />
      <div className="flex justify-around">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `flex flex-col items-center p-2 ${
              isActive ? "text-blue-500" : "text-gray-600"
            }`
          }
        >
          <BeakerIcon className="h-6 w-16" />
          <span className="text-xs">Build Meal</span>
        </NavLink>
        <NavLink
          to="/dose"
          className={({ isActive }) =>
            `flex flex-col items-center p-2 ${
              isActive ? "text-blue-500" : "text-gray-600"
            }`
          }
        >
          <CalculatorIcon className="h-6 w-16" />
          <span className="text-xs">Calculate Dose</span>
        </NavLink>
        <NavLink
          to="/log"
          onClick={handleSwitchToLogTab}
          className={({ isActive }) =>
            `flex flex-col items-center p-2 ${
              isActive ? "text-blue-500" : "text-gray-600"
            }`
          }
        >
          <CheckIcon className="h-6 w-16" />
          <span className="text-xs">Log</span>
        </NavLink>
        <NavLink
          to="/graph"
          className={({ isActive }) =>
            `flex flex-col items-center p-2 ${
              isActive ? "text-blue-500" : "text-gray-600"
            }`
          }
        >
          <ChartBarIcon className="h-6 w-16" />
          <span className="text-xs">Graph</span>
        </NavLink>
      </div>
    </div>
  );
};

export default TabBar;
