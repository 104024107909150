import React from "react";
import { useDispatch } from "react-redux";
import { updateFoodItem, removeFoodItem } from "../store/food-items-slice";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { FoodItemProps } from "../constants";

const FoodItem: React.FC<FoodItemProps> = ({
  id,
  type = "",
  carbs = 0,
  servingSize = 1,
}) => {
  const dispatch = useDispatch();

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFoodItem({ id, type: e.target.value, carbs, servingSize }));
  };

  const handleCarbsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFoodItem({ id, type, carbs: Number(e.target.value), servingSize })
    );
  };

  const handleServingSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      updateFoodItem({ id, type, carbs, servingSize: Number(e.target.value) })
    );
  };

  const handleRemove = () => {
    dispatch(removeFoodItem(id));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return (
    <li className="flex flex-col mb-2 w-full max-w-md mx-auto">
      <div className="flex justify-between items-center p-4 bg-white shadow rounded">
        <input
          type="text"
          value={type}
          placeholder="Food"
          className="border rounded p-2 flex-1 mr-2"
          onChange={handleTypeChange}
        />
        <input
          type="number"
          value={carbs}
          placeholder="Carbs"
          className="border rounded p-2 w-10 mr-2"
          onChange={handleCarbsChange}
          onFocus={handleFocus}
        />
        <select
          value={servingSize}
          onChange={handleServingSizeChange}
          className="border rounded p-2 w-15"
        >
          <option value="0.125">1/8</option>
          <option value="0.25">1/4</option>
          <option value="0.5">1/2</option>
          <option value="0.75">3/4</option>
          <option value="1">1</option>
          <option value="1.5">1.5</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
        <button onClick={handleRemove} className="p-2 w-10">
          <XCircleIcon className="h-6 text-customRed" />
        </button>
      </div>
    </li>
  );
};

export default FoodItem;
