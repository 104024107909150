import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Chart, AxisOptions, UserSerie } from "react-charts";
import { ChartDataState, ONE_DAY_AGO } from "../constants";

type ChartDatum = { datetime: Date; value: number };

const dateFilter = (datum: ChartDatum) => {
  return datum.datetime > ONE_DAY_AGO;
};

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
}).format;

const GraphPage: React.FC = () => {
  const {
    data: glucoseData,
    loading: glucoseLoading,
    reloading: glucoseReloading,
    error: glucoseError,
  } = useSelector((state: RootState) => state.glucoseHistoryChart);

  const {
    items: dosageItems,
    loading: dosageLoading,
    reloading: dosageReloading,
    error: dosageError,
  } = useSelector((state: RootState) => state.dosageLog);

  const glucoseChartData = [
    {
      label: "Blood Glucose",
      data: glucoseData
        .map((datum) => {
          return {
            datetime: new Date(datum.datetime),
            value: datum.value,
          };
        })
        .filter(dateFilter),
    },
  ];

  const dosageChartData = [
    {
      label: "Dosage",
      data: dosageItems
        .map((datum) => {
          return {
            datetime: new Date(datum.mealTime),
            value: datum.administeredDose,
          };
        })
        .filter(dateFilter),
    },
  ];

  const bloodSugarPrimaryAxis = React.useMemo(
    (): AxisOptions<ChartDatum> => ({
      getValue: (datum) => datum.datetime,
      scaleType: "time",
      formatters: {
        scale: (date: Date) => dateFormatter(date), // Custom time format
      },
      min: ONE_DAY_AGO,
      max: new Date(),
    }),
    []
  );

  const bloodSugarSecondaryAxes = React.useMemo(
    (): AxisOptions<ChartDatum>[] => [
      {
        getValue: (datum) => datum.value,
        min: 20,
        max: 400,
      },
    ],
    []
  );

  const dosagePrimaryAxis = React.useMemo(
    (): AxisOptions<ChartDatum> => ({
      getValue: (datum) => datum.datetime,
      scaleType: "time",
      formatters: {
        scale: (date: Date) => dateFormatter(date), // Custom time format
      },
      min: ONE_DAY_AGO,
      max: new Date(),
    }),
    []
  );

  const dosageSecondaryAxes = React.useMemo(
    (): AxisOptions<ChartDatum>[] => [
      {
        getValue: (datum) => datum.value,
        elementType: "bar",
      },
    ],
    []
  );

  return (
    <div className="flex justify-center h-screen">
      {(glucoseLoading || dosageLoading) && (
        <div className="text-xl">
          <div>Loading...</div>
        </div>
      )}
      {!glucoseLoading && (
        <div
          className="absolute top-0 left-0 right-0"
          style={{ height: "300px" }}
        >
          <Chart
            options={{
              data: glucoseChartData,
              primaryAxis: bloodSugarPrimaryAxis,
              secondaryAxes: bloodSugarSecondaryAxes,
            }}
          />
        </div>
      )}
      {!dosageLoading && dosageItems.length && (
        <div
          className="absolute top-100 left-0 right-0"
          style={{ height: "300px", top: "350px" }}
        >
          <Chart
            options={{
              data: dosageChartData,
              primaryAxis: dosagePrimaryAxis,
              secondaryAxes: dosageSecondaryAxes,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GraphPage;
