import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  READ_GLUCOSE_HISTORY_CHART,
  ChartDatum,
  ChartDataState,
} from "../constants";
import { RootState } from ".";
import { setLastSuccessfulChartLoad } from "./last-successful-chart-load-slice";
import { setCurrentlyLoadingChart } from "./currently-loading-chart-slice";

const initialState: ChartDataState = {
  data: [
    {
      datetime: new Date().toISOString(),
      value: 100,
    },
  ],
  loading: false,
  reloading: false,
  error: null,
};

let debounceTimeout: ReturnType<typeof setTimeout> | null = null;
let localLastSuccessfulChartLoad = 0;

// Fetch chart entries from API
export const fetchGlucoseHistoryChartEntries = createAsyncThunk(
  "log/fetchGlucoseHistoryChartEntries",
  async (_, { getState, dispatch }) => {
    return new Promise<ChartDataState>((resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      debounceTimeout = setTimeout(async () => {
        const state = getState() as RootState;
        const authKey = state.auth.apiKey;

        try {
          dispatch(setCurrentlyLoadingChart(true));
          const response = await fetch(
            `${READ_GLUCOSE_HISTORY_CHART}?ts=${Date.now()}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                auth: authKey,
              }),
            }
          );
          const data = await response.json();
          dispatch(setLastSuccessfulChartLoad(Date.now()));
          dispatch(setCurrentlyLoadingChart(false));
          localLastSuccessfulChartLoad = Date.now();

          const glucoseDataResultMap = new Map<Date, ChartDatum>();

          for (const log of data.logs) {
            for (const historyEntry of log.glucoseHistory) {
              const datetime = new Date(historyEntry.time);
              glucoseDataResultMap.set(datetime, {
                datetime: datetime.toISOString(),
                value: historyEntry.value,
              });
            }
          }
          const glucoseDataResultData: ChartDatum[] = [];
          glucoseDataResultMap.forEach((value) => {
            glucoseDataResultData.push(value);
          });
          glucoseDataResultData.sort((a, b) => {
            return (
              new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
            );
          });

          const glucoseDataResult: ChartDataState = {
            data: glucoseDataResultData,
            loading: false,
            reloading: false,
            error: null,
          };
          resolve(glucoseDataResult);
        } catch (err) {
          dispatch(setCurrentlyLoadingChart(false));
          reject(err);
        }
      }, 100); // Debounce delay
    });
  }
);

const glucoseHistoryChartSlice = createSlice({
  name: "glucoseHistoryChart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlucoseHistoryChartEntries.pending, (state) => {
        if (localLastSuccessfulChartLoad === 0) {
          return {
            ...state,
            loading: true,
            reloading: false,
            error: null,
          };
        } else {
          return {
            ...state,
            loading: false,
            reloading: true,
            error: null,
          };
        }
      })
      .addCase(
        fetchGlucoseHistoryChartEntries.fulfilled,
        (state, action: PayloadAction<ChartDataState>) => {
          return {
            ...state,
            loading: false,
            reloading: false,
            data: action.payload.data,
          };
        }
      )
      .addCase(fetchGlucoseHistoryChartEntries.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          reloading: false,
          error: action.error.message || "Failed to fetch chart entries",
        };
      });
  },
});

export default glucoseHistoryChartSlice.reducer;
