import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { selectTotalCarbs } from "../store/selectors";

const CarbTotalBar: React.FC = () => {
  const totalCarbs = useSelector((state: RootState) => selectTotalCarbs(state));

  return (
    <div className="p-4 bg-white shadow rounded w-full max-w-md mx-auto">
      <p className="text-lg">Total Carbs: {totalCarbs.toFixed(2)} grams</p>
    </div>
  );
};

export default CarbTotalBar;
