import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CurrentBloodSugarState {
  value: number;
}

const initialState: CurrentBloodSugarState = {
  value: 0,
};

const currentBloodSugarSlice = createSlice({
  name: "currentBloodSugar",
  initialState,
  reducers: {
    setCurrentBloodSugar: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setCurrentBloodSugar } = currentBloodSugarSlice.actions;
export default currentBloodSugarSlice.reducer;
