import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../constants";

const initialState: AuthState = {
  isAuthorized: false,
  apiKey: null,
  logVersion: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authorize: (state, action: PayloadAction<AuthState>) => {
      return {
        ...state,
        isAuthorized: true,
        apiKey: action.payload.apiKey,
        logVersion: action.payload.logVersion,
      };
    },
    logout: (state) => {
      return {
        ...state,
        isAuthorized: false,
        apiKey: null,
      };
    },
  },
});

export const { authorize, logout } = authSlice.actions;
export default authSlice.reducer;
